@import url('https://fonts.googleapis.com/css2?family=Inter&family=PT+Mono&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #16202f;
  color: #a8b2d1;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* Offset Anchor Links */

a {
  text-decoration: none;
  color: #a8b2d1;
}

p {
  font-size: 22px;
  line-height: 1.8em;
  letter-spacing: -.01em;
}

p a:hover {
  text-decoration: underline;
}

@media (max-width: 500px) {
  p {
    font-size: 20px;
  }
}

svg {
  font-size: 18px;
}

a:hover,
svg:hover {
  color: #ff0048;
}

.link {
  color: #ff0048;
}

.link-header {
  color: #ff0048;
  text-decoration: underline #ff0048;
  font-size: 20px;
  font-weight: 800;
}

.whole-app-container {
  padding: 0 75px;
  margin: 0 auto;
}

@media(max-width: 768px) {
  .whole-app-container {
    padding: 0 35px;
  }
}

.about-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.nav-container {
  position: relative;
  padding: 20px 35px;
  font-family: 'PT Mono', monospace;
  font-size: 14px;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  grid-gap: 20px;
}

.nav-item {
  display: inline-block;
}

.socials-container {
  position: fixed;
  bottom: 10px;
  left: clamp(.5px, 1vw, 10px);
  display: flex;
  flex-direction: column;
  color: #a8b2d1;
}


@media(max-width: 768px) {

  svg,
  .socials-container:after {
    font-size: 14px;
  }
}

.socials-container:after {
  content: "Let's Connect";
  transform: rotate(-90deg);
  display: block;
  width: 135px;
  padding-bottom: 12px;
  height: 125px;
  margin: 0px auto;
  font-family: 'PT Mono', monospace;
}

.side-item {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 20px;
}

.hero-content {
  min-height: calc(100vh - 86px);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  padding-bottom: 7.5vh;
}

.hero-hello {
  color: #ff0048;
  font-family: 'PT Mono', monospace;
  font-weight: 600;
}

.hero-name {
  color: #ccd6f6;
  margin: 5px 0 0;
}

.hero-intro {
  color: #a8b2d1;
  margin: 8px 0 32px;
}

.hero-name,
.hero-intro {
  font-size: clamp(32px, 10vw, 70px);
  font-family: 'Inter', sans-serif;
}



.CTA-button {
  border: 1px solid #ff0048;
  color: #ff0048;
  padding: 14px 20px;
  font-family: 'PT Mono', monospace;
  font-weight: 600;
  border-radius: 0 15px 0 15px;
}

.CTA-button:hover {
  background-color: rgb(255, 0, 72, 0.1);
}

.section-container {
  text-align: left;
  padding-top: clamp(25px, 5vh, 50px);
  padding-bottom: clamp(25px, 5vh, 50px);
  margin-bottom: clamp(50px, 12vh, 150px);
}

.container-wide {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.header-1 {
  text-align: left;
  color: #ccd6f6;
  font-size: clamp(32px, 8vw, 52px);
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: .05em;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-top: 0;
  margin-bottom: 50px;
  text-shadow: 4px 4px #16202f, 5px 5px rgb(255, 0, 72, .6);
}

.header-1:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  flex: 1;
  margin-top: .1em;
  margin-left: .75em;
  background-color: #ff0048;
}

.header-2 {
  color: #a8b2d1;
  font-size: 20px;
  font-weight: 800;
}

.header-3 {
  color: #ccd6f6;
  font-size: 36px;
  text-align: center;
  font-weight: 600;
}

.header-4 {
  color: #a8b2d1;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
}

.header-5 {
  color: #ccd6f6;
  font-size: 18px;
  font-family: 'PT Mono', monospace;
  text-align: center;
}

.grid-item {
  background-color: #1f2e45;
  padding: 15px 20px;
  position: relative;
  transition: all .3s ease;
}

.grid-item:after {
  content: '→';
  color: #a8b2d133;
  font-size: 48px;
  font-weight: 100;
  position: absolute;
  bottom: 10px;
  right: 10px;
  transition: all .2s ease;
}

.grid-item .header-2 {
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 400;
  border-left: 1px solid #a8b2d133;
  padding-left: 15px;
}

.grid-item-content {
  font-size: 18px;
  line-height: 1.6em;
  opacity: .7;
}

.grid-item:hover {
  background-color: rgba(78, 114, 168, 0.1);
}

.grid-item:hover:after {
  color: #ff0048;
  right: -20px;
}

.inline-text-callout {
  font-weight: bold;
}

.inline-text-callout a {
  color: #ff0048;
}

.red-border-accent,
.CTA-button {
  box-shadow: 4px 4px #16202f, -4px -4px #16202f, 5px 5px #ff0048, -5px -5px #ff0048;
  transition: all .3s ease;
}

.red-border-accent:hover,
.CTA-button:hover {
  box-shadow: 9px 9px #16202f, -9px -9px #16202f, 10px 10px #ff0048, -10px -10px #ff0048;
}

.tech-whole-container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border: 1px solid #ff0048;
  background-color: rgb(255, 0, 72, 0.1);
  border-radius: 0 25px 0 25px;
  max-width: 100%;
  padding: 35px;
}

.tech-whole-container .header-4 {
  margin: 0;
  color: #ff0048;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: .05em;
  margin-bottom: .5em;
  text-align: center;
}

.tech-whole-container p {
  margin: 0;
}

.tech-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tech-header {
  text-align: center;
  font-size: 18px;
}

.tech {
  padding-right: 8px;
  font-size: 18px;
  font-family: 'PT Mono', monospace;
  margin: 0;
}

.tech:after {
  content: " | ";
  color: #ff0048;
}

.tech:last-of-type:after {
  display: none;
}

.featured-projects-container {
  display: flex;
  flex-direction: column;
  grid-gap: 75px;
}

.featured-project {
  display: flex;
  flex-direction: row;
  grid-gap: 75px;
  background-color: #1f2e45;
}

@media(max-width: 768px) {
  .featured-project {
    grid-row-gap: 20px;
    display: flex;
    flex-direction: column-reverse;
  }
}

.featured-project-content {
  padding: 35px
}

.featured-project-content p {
  margin: 0;
}

.featured-project-content .link-header {
  font-size: 32px;
  line-height: 1.3em;
  font-weight: bold;
  border-left: 1px solid #a8b2d133;
  padding-left: 15px;
  margin-bottom: 25px;
}

.featured-project-content .featured-description {
  opacity: .7;
  margin-bottom: 25px;
  font-weight: 300;
}

.featured-project-content,
.featured-project-image {
  flex: 1;
}

.project-tile-link:hover {
  color: #a8b2d1;
}

.featured-project-image-container {
  max-width: 100%;
  width: 40%;
  display: flex;
  position: relative;
  transition: all .3s ease;
}

@media(max-width: 768px) {
  .featured-project-image-container {
    aspect-ratio: 1.5;
    width: 100%;
  }
}

.featured-project-image {
  overflow: hidden;
}

.featured-project-image,
.featured-project-image:before,
.featured-project-image:after {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: top left;
}

.featured-project-image:before {
  content: '';
  transition: all .4s ease;
  filter: blur(0px);
  background-image: inherit;
  filter: grayscale(1) contrast(.8) brightness(.4) sepia(100%) hue-rotate(177deg);
}

.featured-project-image:after {
  content: '';
  background-image: inherit;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  transition: all .3s ease;
}

.featured-project:hover .featured-project-image:after {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.featured-project:hover .featured-project-image:before {
  filter: grayscale(1) contrast(.8) brightness(.4) sepia(100%) hue-rotate(177deg) blur(15px);
}

.inline-cta {
  display: inline-flex;
  align-items: center;
  padding-right: 25px;
  color: #ff0048;
  font-weight: bold;
}

.inline-cta:hover {
  text-decoration: underline;
}

.inline-cta svg {
  margin-left: .5em;
}

.tech-items-wrapper {
  margin-bottom: 25px;
}

.tech-items-wrapper:before {
  content: 'Built with:';
  display: block;
  color: #ff0048;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: .05em;
  margin-bottom: .5em;
}

.tech-items-wrapper>.tech {
  display: inline-block;
}

.projects {
  margin-top: 100px;
}

.project-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  justify-content: center;
}

@media(max-width: 768px) {
  .project-grid-wrapper {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
}

.blog-info-container {
  margin-bottom: 75px;
}

.blog-info {
  max-width: 650px;
}

.blog-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  justify-content: center;
}

@media(max-width: 768px) {
  .blog-grid-wrapper {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
}

.blog-grid-wrapper>.grid-item:last-of-type {
  border: 1px solid rgb(255, 0, 72, 0.3);
  background-color: rgb(255, 0, 72, 0.1);

}

.blog-grid-wrapper>.grid-item:last-of-type:hover {
  border: 1px solid #ff0048;
}

.blog-grid-wrapper>.grid-item:last-of-type:hover:after {
  transform: translateX(30px)
}

.blog-link:hover {
  color: #a8b2d1;
}

.contact-container {
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  margin-bottom: 150px;
  max-width: 700px;
}

.contact-container .header-1 {
  margin-bottom: 0;
}

.underline {
  width: 550px;
  height: 1px;
  background-color: #ff0048;
  margin: 0 auto;
}

.contact-content {
  color: #fff;
  font-weight: 300;
}

.contact-button-container {
  display: flex;
  grid-gap: 50px;
  margin-top: 25px;
}

.footer-cta-container {
  text-align: center;
  margin-bottom: 75px;
}

.footer-cta {
  font-family: 'PT Mono', monospace;
}

.about-header-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 50px;
}

.about-header-container .header-1 {
  margin-bottom: 0;
}

.about-image-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.about-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {
  .about-header-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}